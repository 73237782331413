import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ReactWordcloud from 'react-wordcloud';
import { Tab } from '@headlessui/react';
import Tooltip from 'components/common/Tooltip';
import { PostContentCard } from 'components/common/inf-profile';
import styles from 'assets/scss/pages/influencer-profile.module.scss';
import { NoData } from 'components/common/NoData';
import { contentIcon } from 'constants/images';
import useElementDimensions from 'hooks/useElementDimentions';

const ContentSection = ({ topPosts, recentPosts, brandPosts, hashtags }) => {
  // console.log('caption data checking', topPosts);
  const { dimensions, ref: targetRef } = useElementDimensions();

  const hashtagsToShow = useMemo(
    () =>
      hashtags
        ?.map((tag, i) => ({
          text: tag,
          value: i,
        }))
        .slice(0, 20),
    [hashtags]
  );

  const callbacks = {
    getWordTooltip: (word) => '',
    // getWordColor: (word) => (word.value > 50 ? 'blue' : 'red'),
  };

  const options = {
    rotations: 1,
    rotationAngles: [0, -90],
    colors: ['#7c087f', '#7F60FF', '#FF00A2', '#E289F2'],
    fontFamily:
      'Poppins, -apple-system, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif',
  };

  return (
    <div className={styles.navCategory}>
      <div className={styles.sectionHeader}>
        <img src={contentIcon} alt="" />
        <h2 className={styles.contntTxt}>Content</h2>
      </div>
      <div className={styles.sectionBody}>
        <div className={styles.sectioncategoryHeader}>
          <h3 className={styles.contcatTxt}>HASHTAGS</h3>
          <Tooltip label="Hashtags used in influencer's profile and posts." />
        </div>
        <div ref={targetRef} style={{ height: '400px', width: '100%' }}>
          {hashtagsToShow?.length ? (
            <>
              {dimensions ? (
                <ReactWordcloud
                  words={hashtagsToShow}
                  options={options}
                  callbacks={callbacks}
                  size={[dimensions.width, dimensions.height]}
                />
              ) : null}
            </>
          ) : (
            <NoData
              height={400}
              description="We don't have hashtags data available currently."
            />
          )}
        </div>
        {/* <ul className={styles.hashtagsList}>
          {hashtagsToShow?.map((hashtag) => (
            <li className={styles.hastagItems} key={hashtag}>
              <p className={styles.hastagText}>{hashtag}</p>
            </li>
          ))}
        </ul> */}
        <Tab.Group>
          <div className={styles.postHeader} style={{ marginTop: '50px' }}>
            <div className={styles.contntDiv}>
              <h2 className={styles.insightHeading}>CONTENT</h2>
              {/* <span className={styles.allTxt}>View All</span> */}
            </div>
            <div>
              <Tab.List className={styles.TimeBox}>
                <Tab
                  className={({ selected }) =>
                    clsx(styles.yearList, selected && styles.activeYearList)
                  }
                >
                  Top Posts
                </Tab>
                <Tab
                  className={({ selected }) =>
                    clsx(styles.yearList, selected && styles.activeYearList)
                  }
                >
                  Recent Posts
                </Tab>
                <Tab
                  className={({ selected }) =>
                    clsx(styles.yearList, selected && styles.activeYearList)
                  }
                >
                  Brand Posts
                </Tab>
              </Tab.List>
            </div>
          </div>
          <Tab.Panels>
            <Tab.Panel className={styles.postWrapper}>
              {topPosts?.length ? (
                topPosts.map((post) => (
                  <PostContentCard key={post?.postId} {...post} />
                ))
              ) : (
                <NoData
                  height={245}
                  description="We don't have top posts available currently."
                />
              )}
            </Tab.Panel>
            <Tab.Panel className={styles.postWrapper}>
              {recentPosts?.length ? (
                recentPosts.map((post) => (
                  <PostContentCard key={post?.postId} {...post} />
                ))
              ) : (
                <NoData
                  height={245}
                  description="We don't have recent posts available currently."
                />
              )}
            </Tab.Panel>
            <Tab.Panel className={styles.postWrapper}>
              {brandPosts?.length ? (
                brandPosts.map((post) => (
                  <PostContentCard key={post?.postId} {...post} />
                ))
              ) : (
                <NoData
                  height={245}
                  description="We don't have brand posts available currently."
                />
              )}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

ContentSection.propTypes = {
  topPosts: PropTypes.array,
  recentPosts: PropTypes.array,
  brandPosts: PropTypes.array,
  hashtags: PropTypes.array,
};

export default ContentSection;
