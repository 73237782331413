import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { queryClient } from 'App';
import {
  getInfCategories,
  getInfluencersUpdated,
  getStateList,
  getSubCategories,
  AddInfluencerSearch,
} from 'api/brand/search';
import { useMutation, useQuery } from '@tanstack/react-query';
import Table from 'components/common/Table';
import Menu from 'components/common/Menu';
import FilterDropdown from 'components/common/FilterDropdown';
import Button from 'components/common/Button';
import FilterMenu from 'components/common/FilterDropdown/FilterMenu';
import ActionBar from 'components/brand-dashboard/ActionBar';
import { FilterChip } from 'components/common/Chip';
import { Avatar } from 'components/common/Avatar';
import Tooltip from 'components/common/Tooltip';
import TableError from 'components/common/Table/TableError';
import { useDialog } from 'stores/dialog';
import useFilterProps from 'hooks/useFilterProps';
import useDebounce from 'hooks/useDebounce';
import { PLATFORMS, INF_USER_TYPES } from 'constants/config/filters';
import { DIALOGS } from 'constants/config/dialog-names';
import { formatNumber } from 'utils';
import routes from 'constants/routes';
import styles from 'assets/scss/pages/search.module.scss';
import {
  cogwheelIcon,
  categoryIcon,
  searchImg,
  followerSvg,
  rcScoreSvg,
  materialSvg,
  locationSvg,
  ytIcon,
  twitterSvg,
  facebookSvg,
  instaSvg,
  blueTickIcon,
} from 'constants/images';
import { ReactComponent as MoreIcon } from 'assets/images/more-icon.svg';
// import { ReactComponent as AddIcon } from 'assets/images/add_grey_icon.svg';
import { ReactComponent as StatsIcon } from 'assets/images/stats-icon.svg';
import { ReactComponent as ListIcon } from 'assets/images/custom-list.svg';
import { ReactComponent as RupeeIcon } from 'assets/images/indian-rupee-sign-solid.svg';
import { toast } from 'react-toastify';
import { uinqueCategories } from './influencer-profile/formatData/sortData';
/* eslint-disable camelcase */

const columns = [
  {
    Header: 'Profile',
    accessor: 'full_name',
    width: 240,
    minWidth: 240,
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      return (
        <Link
          to={`${routes.BRAND_SEARCH_INF}/${row?.id}`}
          target="_blank"
          className={styles.profileDiv}
        >
          <Avatar src={row?.profile_image} alt="" size="large" />
          <p>{row?.full_name}</p>
          {row?.is_registered ? (
            <img src={blueTickIcon} alt="verified" />
          ) : null}
        </Link>
      );
    },
  },
  {
    Header: 'Platform',
    accessor: 'platform',
    width: 60,
    minWidth: 60,
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      const icon = useMemo(() => {
        switch (row?.platform) {
          case 'youtube':
            return ytIcon;
          case 'instagram':
            return instaSvg;
          case 'twitter':
            return twitterSvg;
          case 'facebook':
            return facebookSvg;
          default:
            return null;
        }
      }, [row]);
      return icon ? <Avatar src={icon} alt="" /> : null;
    },
  },
  // {
  //   Header: 'SIMINCO Scrore',
  //   accessor: 'smincoScore',
  //   info: 'Platform Score',
  //   Cell: (cellInfo) => {
  //     const row = cellInfo?.row?.original;
  //     return <p className={styles.downloadedTxt}>{row?.smincoScore}</p>;
  //   },
  // },
  {
    Header: 'Categories',
    accessor: 'categories',
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      const categories = uinqueCategories(row?.subcategories || []);
      const categoriesCount = categories?.length;
      return (
        <>
          {categoriesCount > 0 && (
            <div className={styles.catgryDiv}>
              {categoriesCount > 0 && (
                <span className={styles.catgrySpan}>{categories[0]}</span>
              )}
              {categoriesCount > 1 ? (
                <>
                  <Tooltip
                    className={styles.catgryTxt}
                    label={
                      <div className={styles.moreContainer}>
                        <ul>
                          {categories
                            ?.slice(1, categoriesCount + 1)
                            ?.map((category, i) => (
                              <li key={i}>{category}</li>
                            ))}
                        </ul>
                      </div>
                    }
                  >
                    +{categoriesCount - 1} More
                  </Tooltip>
                </>
              ) : null}
            </div>
          )}
        </>
      );
    },
  },
  { Header: 'Followers', accessor: 'followers', disableSortBy: false },
  { Header: 'Avg Likes', accessor: 'avgLikes' },
  { Header: 'Avg Views', accessor: 'avgViews' },
  {
    Header: 'ER',
    accessor: 'engagementRate',
    info: 'Engagement Rate',
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      return <p className={styles.downloadedTxt}>{row?.engagementRate}%</p>;
    },
  },
  { Header: 'Location', accessor: 'location' },
  {
    Header: '',
    accessor: 'options',
    width: 20,
    Cell: (cellInfo) => {
      const row = cellInfo?.row?.original;
      const setDialog = useDialog((state) => state.setDialog);
      return (
        <Menu
          renderButton={() => (
            <Menu.Button>
              <MoreIcon />
            </Menu.Button>
          )}
        >
          {/* <Menu.Item
            onClick={() =>
              setDialog(DIALOGS.BRAND_INF_COST_OVERVIEW, {
                influencerId: row?.id,
                platform: row?.platform,
              })
            }
            disabled={!row?.is_registered}
          >
            <RupeeIcon />
            <span>View Cost</span>
          </Menu.Item> */}
          <Menu.Item
            onClick={() =>
              setDialog(DIALOGS.BRAND_INF_PROFILE_OVERVIEW, {
                influencerId: row?.id,
                platform: row?.platform,
              })
            }
          >
            <StatsIcon />
            <span>Preview</span>
          </Menu.Item>
          <Menu.Item
            onClick={() =>
              setDialog(DIALOGS.ADD_INF_TO_CUSTOM_LIST, {
                influencerIds: [row?.id],
              })
            }
          >
            <ListIcon />
            <span>Custom List</span>
          </Menu.Item>
        </Menu>
      );
    },
  },
];

export const prefetchInfluencers = async ({
  page = 1,
  rowsPerPage = 10,
  search = '',
  categoriesFilter = null,
  subCategoriesFilter = null,
  stateFilter = null,
  cityFilter = null,
  followersFilter = null,
  ageGroupFilter = null,
  smincoScoreFilter = null,
  platformFilter = 'instagram',
  sortBy = 'followers',
  sortDir = 'DESC',
  engagementRateFilter = null,
  infStatus = null,
} = {}) => {
  queryClient.prefetchQuery({
    queryKey: [
      'brand-search-influencer-list',
      page,
      rowsPerPage,
      search,
      categoriesFilter?.toString(),
      subCategoriesFilter?.toString(),
      stateFilter?.toString(),
      cityFilter?.toString(),
      followersFilter,
      ageGroupFilter,
      smincoScoreFilter,
      platformFilter,
      sortBy + sortDir,
      engagementRateFilter?.toString(),
      infStatus,
    ],
    queryFn: () => {
      const hasEngagementFilter = Boolean(engagementRateFilter);
      const param = {
        pageNo: page || 1,
        pageSize: rowsPerPage,
        search: search || undefined,
        category_id: categoriesFilter || undefined,
        subcategory_id: subCategoriesFilter || undefined,
        location: stateFilter || undefined,
        city: cityFilter || undefined,
        subscriber_count_from: followersFilter?.[0] || undefined,
        subscriber_count_to: followersFilter?.[1] || undefined,
        is_youtube: platformFilter === 'youtube',
        is_instagram: platformFilter === 'instagram',
        is_facebook: platformFilter === 'facebook',
        is_twitter: platformFilter === 'twitter',
        follower_sort: sortBy === 'followers' ? sortDir : undefined,
        engagement_rate_from: hasEngagementFilter
          ? engagementRateFilter[0]
          : undefined,
        engagement_rate_to: hasEngagementFilter
          ? engagementRateFilter[1]
          : undefined,
        is_registered: infStatus ? infStatus === 'verified' : undefined,
        age_from: ageGroupFilter?.[0] || undefined,
        age_to: ageGroupFilter?.[1] || undefined,
        // sminco_score_from: smincoScoreFilter?.[0] || undefined,
        // sminco_score_to: smincoScoreFilter?.[1] || undefined,
      };
      return getInfluencersUpdated(param);
    },
    staleTime: 30 * 60 * 1000, // 30 minute in milliseconds
    cacheTime: 60 * 60 * 1000, // 60 minute in milliseconds
  });
};

const InfluencerList = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [hasRequested, setHasRequested] = useState(false);
  const location = useLocation();
  const locationState = location.state;
  const tableRef = useRef(null);
  const {
    page,
    rowsPerPage,
    changePage,
    changeRowsPerPage,
    search,
    changeSearch,
    sortBy,
    changeSortBy,
    filter,
    changeFilter,
  } = useFilterProps({
    defaultFilters: { platform: 'instagram' },
    defaultSortBy: [{ id: 'followers', desc: true }],
  });

  const debouncedSearchQuery = useDebounce(search);
  const platformFilter = filter.platform;
  const categoriesFilter = filter.categories;
  const subCategoriesFilter = filter.subCategories;
  const stateFilter = filter.state;
  const cityFilter = filter.city;
  const followersFilter = filter.followers;
  const ageGroupFilter = filter.ageGroup;
  const smincoScoreFilter = filter.smincoScore;
  const engagementRateFilter = filter.engagement;
  const costFilter = filter.cost;
  const infStatus = filter.status;
  const sortDir = sortBy?.[0]?.desc ? 'DESC' : 'ASC';

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [
      'brand-search-influencer-list',
      page,
      rowsPerPage,
      debouncedSearchQuery,
      categoriesFilter?.toString(),
      subCategoriesFilter?.toString(),
      stateFilter?.toString(),
      cityFilter?.toString(),
      followersFilter,
      ageGroupFilter,
      smincoScoreFilter,
      platformFilter,
      sortBy?.[0]?.id + sortDir,
      engagementRateFilter?.toString(),
      infStatus,
    ],
    queryFn: () => {
      const param = {
        pageNo: page || 1,
        pageSize: rowsPerPage,
        search: debouncedSearchQuery || undefined,
        category_id: categoriesFilter || undefined,
        subcategory_id: subCategoriesFilter || undefined,
        location: stateFilter || undefined,
        city: cityFilter || undefined,
        subscriber_count_from: followersFilter?.[0] || undefined,
        subscriber_count_to: followersFilter?.[1] || undefined,
        is_youtube: platformFilter ? platformFilter === 'youtube' : undefined,
        is_instagram: platformFilter
          ? platformFilter === 'instagram'
          : undefined,
        is_facebook: platformFilter ? platformFilter === 'facebook' : undefined,
        is_twitter: platformFilter ? platformFilter === 'twitter' : undefined,
        follower_sort: sortBy?.[0]?.id === 'followers' ? sortDir : undefined,
        engagement_rate_from: engagementRateFilter
          ? engagementRateFilter[0]
          : undefined,
        engagement_rate_to: engagementRateFilter
          ? engagementRateFilter[1]
          : undefined,
        age_from: ageGroupFilter?.[0] || undefined,
        age_to: ageGroupFilter?.[1] || undefined,
        // sminco_score_from: smincoScoreFilter?.[0] || undefined,
        // sminco_score_to: smincoScoreFilter?.[1] || undefined,
        is_registered: infStatus ? infStatus === 'verified' : undefined,
      };
      return getInfluencersUpdated(param);
    },
    staleTime: 30 * 60 * 1000, // 30 minute in milliseconds
    cacheTime: 60 * 60 * 1000, // 60 minute in milliseconds
  });

  const { data: categories } = useQuery({
    queryKey: ['brand-categories'],
    queryFn: () => getInfCategories(),
  });
  const { data: subCategories } = useQuery({
    queryKey: ['brand-subcategories'],
    queryFn: () => getSubCategories(),
  });

  const { data: states } = useQuery({
    queryKey: ['brand-states'],
    queryFn: () => getStateList(),
  });

  useEffect(() => {
    if (locationState?.search) {
      changeSearch(locationState.search);
    }
    if (locationState?.categories) {
      changeFilter('categories', locationState.categories);
    }
    if (locationState?.subCategories) {
      changeFilter('subCategories', locationState.subCategories);
    }
    if (locationState?.platform) {
      changeFilter('platform', locationState.platform);
    }
  }, [locationState, changeSearch, changeFilter]);

  useEffect(() => {
    if (platformFilter === 'instagram' || platformFilter === 'facebook') {
      tableRef.current?.setHiddenColumns(['avgViews']);
    } else {
      tableRef.current?.setHiddenColumns([]);
    }
  }, [platformFilter]);

  const onPlatformChange = (newValue) => {
    changeFilter('platform', newValue);
  };

  const onInfStatusChange = (newValue) => {
    changeFilter('status', newValue);
  };

  const onCategoriesChange = (newValue) => {
    changeFilter('categories', newValue);
    changeFilter('subCategories', []);
  };

  const onSubCategoriesChange = (newValue) => {
    changeFilter('subCategories', newValue);
  };

  const onStateChange = (newValue) => {
    changeFilter('state', newValue);
    changeFilter('city', []);
  };

  const onCityChange = (newValue) => {
    changeFilter('city', newValue);
  };

  const onFollowersChange = (newValue) => {
    changeFilter('followers', newValue);
  };

  const onAgeGroupChange = (newValue) => {
    changeFilter('ageGroup', newValue);
  };

  const onSmincoScoreChange = (newValue) => {
    changeFilter('smincoScore', newValue);
  };

  const onEngagementRateChange = (newValue) => {
    changeFilter('engagement', newValue);
  };

  const onCostChange = (newValue) => {
    changeFilter('cost', newValue);
  };

  const onSearchChange = (e) => {
    changeSearch(e.target.value);
  };

  const onCategoryRemove = (id) => {
    onCategoriesChange(
      categoriesFilter?.filter((categoryId) => categoryId !== id)
    );
  };

  const onSubCategoryRemove = (id) => {
    onSubCategoriesChange(
      subCategoriesFilter?.filter((categoryId) => categoryId !== id)
    );
  };

  const onStateRemove = (id) => {
    onStateChange(stateFilter?.filter((state) => state !== id));
  };

  const onCityRemove = (id) => {
    onCityChange(cityFilter?.filter((state) => state !== id));
  };

  // const onPlatformRemove = () => {
  //   onPlatformChange('');
  // };

  const onFollowersFilterRemove = () => {
    onFollowersChange('');
  };

  const onEngagementRateFilterRemove = () => {
    onEngagementRateChange(undefined);
  };
  const onCostFilterRemove = () => {
    onCostChange(undefined);
  };

  const resetRowSelection = () => tableRef?.current?.resetSelectedRows();

  const onClearFilter = () => {
    changeFilter('categories', []);
    changeFilter('subCategories', []);
    changeFilter('state', []);
    changeFilter('city', []);
    changeFilter('followers', undefined);
    changeFilter('engagement', undefined);
    changeFilter('cost', undefined);
  };

  const selectdCategories = useMemo(
    () =>
      categories?.filter((category) => categoriesFilter?.includes(category.id)),
    [categoriesFilter, categories]
  );

  const selectdSubCategories = useMemo(
    () =>
      subCategories?.filter((category) =>
        subCategoriesFilter?.includes(category.id)
      ),
    [subCategoriesFilter, subCategories]
  );

  const selectedPlatform = useMemo(
    () => PLATFORMS.find((platform) => platform.value === filter.platform),
    [filter]
  );

  const filteredSubCategoryList = useMemo(
    () =>
      categoriesFilter?.length > 0
        ? subCategories?.filter(({ categoryId }) =>
            categoriesFilter.includes(categoryId)
          )
        : subCategories,
    [categoriesFilter, subCategories]
  );

  const filteredCities = useMemo(
    () =>
      states
        ?.reduce(
          (accumulator, state) =>
            stateFilter?.includes(state?.name) || !stateFilter?.length
              ? [...accumulator, ...state.cities]
              : accumulator,
          []
        )
        .sort()
        .map((city) => ({ name: city })),
    [states, stateFilter]
  );

  const influencers = useMemo(
    () =>
      data?.data?.influencers?.rows?.map((inf = {}) => {
        inf.platform = platformFilter;
        inf.location = inf?.city;
        if (platformFilter === 'youtube') {
          const {
            avg_likes: avgLikes,
            avg_views: avgViews,
            subscriber_count: followers,
            avg_enagagement_rate: engagementRate,
            profile_image_url: youtubeProfile,
            sminco_score: smincoScore,
          } = inf?.influencer_youtube || {};
          return {
            ...inf,
            profile_image: inf?.profile_image || youtubeProfile,
            smincoScore: formatNumber(smincoScore),
            followers: formatNumber(followers),
            avgLikes: formatNumber(avgLikes),
            avgViews: formatNumber(avgViews),
            engagementRate: formatNumber(engagementRate),
          };
        }
        if (platformFilter === 'instagram') {
          const followers = inf?.influencer_instagram?.followers_count;
          const engagementRate =
            inf?.influencer_instagram?.avg_enagagement_rate;
          const smincoScore = inf?.influencer_instagram?.sminco_score;
          const {
            avg_likes: avgLikes,
            avg_comments: avgComments,
            profile_image_url: instagramProfile,
          } = inf?.influencer_instagram?.media_stat || {};
          return {
            ...inf,
            profile_image: inf?.profile_image || instagramProfile,
            smincoScore: formatNumber(smincoScore),
            followers: formatNumber(followers),
            avgLikes: formatNumber(avgLikes),
            avgComments: formatNumber(avgComments),
            avgViews: 0,
            engagementRate: formatNumber(engagementRate),
          };
        }
        if (platformFilter === 'facebook') {
          const {
            followers_count: followers,
            avg_likes: avgLikes,
            avg_comments: avgComments,
            profile_picture: facebookProfile,
            sminco_score: smincoScore,
            engagement_rate: engagementRate,
          } = inf?.influencer_facebook || {};
          return {
            ...inf,
            profile_image: inf?.profile_image || facebookProfile,
            smincoScore: formatNumber(smincoScore),
            followers: formatNumber(followers),
            avgLikes: formatNumber(avgLikes),
            avgComments: formatNumber(avgComments),
            avgViews: 0,
            engagementRate: formatNumber(engagementRate),
          };
        }
        if (platformFilter === 'twitter') {
          const {
            avg_likes: avgLikes,
            avg_impression_count: avgViews,
            avg_reply_count: avgComments,
            followers_count: followers,
            engagement_rate: engagementRate,
            profile_picture: twitterProfile,
            sminco_score: smincoScore,
          } = inf?.influencer_twitter || {};
          return {
            ...inf,
            smincoScore: formatNumber(smincoScore),
            profile_image: inf?.profile_image || twitterProfile,
            followers: formatNumber(followers),
            avgLikes: formatNumber(avgLikes),
            avgViews: formatNumber(avgViews),
            avgComments: formatNumber(avgComments),
            engagementRate: formatNumber(engagementRate),
          };
        }
        return inf;
      }),
    [data, platformFilter]
  );
  const influencersCount = data?.data?.influencers?.count;

  const addInfluencerMutation = useMutation({
    mutationFn: ({ instagramId }) => {
      return AddInfluencerSearch({ instagram_id: instagramId });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['brand-search-influencer-list']);
      toast.success(
        'This influencer is currently under review. Please wait while we approve them.',
        {
          position: 'top-center',
        }
      );
    },
    onError: (error) => {
      queryClient.invalidateQueries(['brand-search-influencer-list']);
      toast.error(
        'This influencer does not exist on the selected platform. Kindly check the entered username.',
        {
          position: 'top-center',
        }
      );
    },
  });

  const requestAddInfluencer = () => {
    if (!hasRequested) {
      addInfluencerMutation.mutate({ instagramId: search.trim() });
      setHasRequested(true);
    }
  };

  useEffect(() => {
    if (!isLoading && !isFetching && influencersCount === 0 && !hasRequested) {
      requestAddInfluencer();
    }
  }, [isLoading, isFetching, influencersCount, hasRequested]);

  useEffect(() => {
    setHasRequested(false);
  }, [debouncedSearchQuery]);

  const showClearButton =
    selectdCategories?.length > 0 ||
    selectdSubCategories?.length > 0 ||
    stateFilter?.length > 0 ||
    cityFilter?.length > 0 ||
    followersFilter;

  const moreFilterCount = (engagementRateFilter ? 1 : 0) + (costFilter ? 1 : 0);
  const followerFilterText = followersFilter
    ? `${formatNumber(followersFilter[0])} - ${formatNumber(
        followersFilter[1]
      )}`
    : 'All';

  const ageGroupFilterText = ageGroupFilter
    ? `${ageGroupFilter[0]} - ${ageGroupFilter[1]}`
    : 'All';

  const smincoScoreFilterText = smincoScoreFilter
    ? `${smincoScoreFilter[0]} - ${smincoScoreFilter[1]}`
    : 'All';

  return (
    <>
      <div className={styles.influencerHeader}>
        <h1 className={styles.inflencrTxt}>Influencer List</h1>

        <div className={styles.categorySelector}>
          <FilterDropdown
            options={PLATFORMS}
            label="Platform"
            startIconUrl={cogwheelIcon}
            multiple={false}
            onChange={onPlatformChange}
            value={filter.platform}
          />
          <FilterDropdown
            options={INF_USER_TYPES}
            label="User Type"
            startIconUrl={cogwheelIcon}
            multiple={false}
            onChange={onInfStatusChange}
            value={infStatus}
          />
          <FilterDropdown
            options={categories}
            label="Categories"
            startIconUrl={categoryIcon}
            labelSelector="name"
            valueSelector="id"
            onChange={onCategoriesChange}
            value={categoriesFilter}
          />
          <FilterDropdown
            options={filteredSubCategoryList}
            label="Sub Categories"
            startIconUrl={categoryIcon}
            labelSelector="name"
            valueSelector="id"
            onChange={onSubCategoriesChange}
            value={subCategoriesFilter}
          />
          <FilterDropdown
            options={states}
            label="State"
            labelSelector="name"
            valueSelector="name"
            startIconUrl={locationSvg}
            onChange={onStateChange}
            value={stateFilter}
          />
          <FilterDropdown
            options={filteredCities}
            label="City"
            labelSelector="name"
            valueSelector="name"
            startIconUrl={locationSvg}
            onChange={onCityChange}
            value={cityFilter}
            isVirtual
          />
          <FilterMenu.RangeSelector
            label="Followers"
            startIconUrl={followerSvg}
            minLimit={1}
            maxLimit={2500000}
            onClear={() => onFollowersChange(null)}
            onApply={onFollowersChange}
            buttonText={followerFilterText}
            value={followersFilter}
            isMenuChild={false}
            placement="bottom-start"
            adaptiveWidth
            portal
            sliderProps={{ step: 500 }}
          />
          <FilterMenu.RangeSelector
            label="Age"
            startIconUrl={followerSvg}
            minLimit={0}
            maxLimit={100}
            onClear={() => onAgeGroupChange(null)}
            onApply={onAgeGroupChange}
            buttonText={ageGroupFilterText}
            value={ageGroupFilter}
            isMenuChild={false}
            placement="bottom-start"
            adaptiveWidth
            portal
            sliderProps={{ step: 1 }}
          />
          {/* <FilterMenu.RangeSelector
            label="SMINCO Score"
            startIconUrl={rcScoreSvg}
            minLimit={0}
            maxLimit={10}
            onClear={() => onSmincoScoreChange(null)}
            onApply={onSmincoScoreChange}
            buttonText={smincoScoreFilterText}
            value={smincoScoreFilter}
            isMenuChild={false}
            placement="bottom-start"
            adaptiveWidth
            portal
            sliderProps={{ step: 1 }}
          /> */}
          <FilterMenu
            label="More"
            startIconUrl={materialSvg}
            filterCount={moreFilterCount}
          >
            <FilterMenu.RangeSelector
              label="Engagement Rate"
              minLimit={0}
              maxLimit={99}
              onClear={onEngagementRateFilterRemove}
              onApply={onEngagementRateChange}
            />
            <FilterMenu.RangeSelector
              label="Cost"
              popoverLabel="Select Cost"
              minLimit={100000}
              maxLimit={600000}
              sliderProps={{ step: 100 }}
              onClear={onCostFilterRemove}
              onApply={onCostChange}
              disabled
            />
          </FilterMenu>
        </div>
      </div>
      <div className="influencerList">
        <div className={styles.influencerSelectors}>
          {selectedPlatform ? (
            <FilterChip
              icon={selectedPlatform.icon}
              label={selectedPlatform.label}
              isActive
            />
          ) : null}
          {selectdCategories?.map(({ id, name, image }) => (
            <FilterChip
              icon={image}
              label={name}
              key={id}
              value={id}
              onRemove={onCategoryRemove}
            />
          ))}
          {selectdSubCategories?.map(({ id, name, image }) => (
            <FilterChip
              icon={image}
              label={name}
              key={id}
              value={id}
              onRemove={onSubCategoryRemove}
            />
          ))}
          {stateFilter?.map((state) => (
            <FilterChip
              label={state}
              key={state}
              value={state}
              onRemove={onStateRemove}
            />
          ))}
          {cityFilter?.map((city) => (
            <FilterChip
              label={city}
              key={city}
              value={city}
              onRemove={onCityRemove}
            />
          ))}
          {followersFilter && (
            <FilterChip
              label={`${followerFilterText} Followers`}
              onRemove={onFollowersFilterRemove}
            />
          )}
          {ageGroupFilter && (
            <FilterChip
              label={`${ageGroupFilterText} Age`}
              onRemove={() => onAgeGroupChange(null)}
            />
          )}
          {smincoScoreFilter && (
            <FilterChip
              label={`${smincoScoreFilterText} SMINCO Score`}
              onRemove={() => onSmincoScoreChange(null)}
            />
          )}
          {engagementRateFilter && (
            <FilterChip
              label={`${engagementRateFilter[0]}-${engagementRateFilter[1]} Engagement Rate`}
              onRemove={onEngagementRateFilterRemove}
            />
          )}
          {costFilter && (
            <FilterChip
              label={`${costFilter[0]}-${costFilter[1]} Cost`}
              onRemove={onCostFilterRemove}
            />
          )}
          {infStatus ? (
            <FilterChip
              label={infStatus === 'verified' ? 'Verified' : 'Not Verified'}
              isActive
            />
          ) : null}
          {showClearButton && (
            <Button variant="text" onClick={onClearFilter}>
              Clear Filters
            </Button>
          )}
        </div>
      </div>
      <div className={styles.influencerHeader}>
        <div className={styles.subCategoryTitle}>
          <h2 className={styles.trendingTxt}>
            {formatNumber(influencersCount, { notation: 'standard' })}{' '}
            Influencers Found
          </h2>
          <div className={styles.searchField}>
            <img className={styles.sIcon} src={searchImg} alt="" />
            <input
              className={styles.searchTxt}
              placeholder="Type to search list below"
              type="text"
              value={search || ''}
              onChange={onSearchChange}
            />
          </div>
        </div>
        <Table
          data={influencers}
          columns={columns}
          paginationProps={{
            currentPage: page,
            changePage,
            count: influencersCount,
            rowsPerPage,
            changeRowsPerPage,
            rowsPerPageOptions: [10, 30, 50],
            withPageInput: true,
          }}
          onSortChange={changeSortBy}
          initialState={{ sortBy: sortBy || [] }}
          isRowsSelectable={infStatus !== 'not-verified'}
          onSlectedRowsChange={setSelectedRows}
          ref={tableRef}
          isLoading={isLoading || isFetching}
          noDataValue={
            <div className={styles.errorContainer}>
              <TableError
                infoText={
                  search
                    ? 'No influencers match the search criteria.'
                    : 'No influencers found.'
                }
              />
              {/* <button
                type="button"
                className={styles.addInfluencer}
                onClick={requestAddInfluencer}
              >
                Request to Add Influencer
              </button> */}
            </div>
          }
        />
      </div>
      {(!infStatus || infStatus === 'verified') && (
        <ActionBar
          resetRowSelection={resetRowSelection}
          selectedRows={selectedRows}
          show={selectedRows.length > 0}
          platform={platformFilter}
        />
      )}
    </>
  );
};

export default InfluencerList;
