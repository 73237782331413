const ADDRESS_TYPES = [
  { label: 'Home', value: 'home' },
  { label: 'Office', value: 'office' },
];
Object.freeze(ADDRESS_TYPES);

const LANGUAGES = [
  { label: 'Hindi', value: 'hindi' },
  { label: 'English', value: 'english' },
  { label: 'Bengali', value: 'bengali' },
  { label: 'Urdu', value: 'urdu' },
  { label: 'Punjabi', value: 'punjabi' },
  { label: 'Marathi', value: 'marathi' },
  { label: 'Telugu', value: 'telugu' },
  { label: 'Tamil', value: 'tamil' },
  { label: 'Gujarati', value: 'gujarati' },
  { label: 'Kannada	', value: 'kannada' },
  { label: 'Odia', value: 'odia' },
  { label: 'Malayalam', value: 'malayalam' },
  { label: 'Assamese', value: 'assamese' },
  { label: 'Santali', value: 'santali' },
  { label: 'Meitei (Manipuri)', value: 'meitei' },
];
Object.freeze(LANGUAGES);

const CATEGORIES = [
  { label: 'Celebrity', value: 'celebrity' },
  { label: 'Fashion', value: 'fashion' },
  { label: 'Beauty', value: 'beauty' },
  { label: 'Sport and Fitness', value: 'sport and fitness' },
  { label: 'Travel', value: 'travel' },
  { label: 'Parenting', value: 'parenting' },
  { label: 'Photography', value: 'photography' },
  { label: 'Music', value: 'music' },
  { label: 'Food & Beverages', value: 'food and beverages' },
  { label: 'Gaming', value: 'gaming' },
  { label: 'Pet', value: 'pet' },
  { label: 'Technology', value: 'technology' },
  { label: 'Entertainment', value: 'entertainment' },
  { label: 'Real Estate', value: 'real Estate' },
  { label: 'Finance', value: 'finance' },
  { label: 'Bloggers and Vloggers', value: 'bloggers and vloggers' },
  { label: 'Luxury', value: 'luxury' },
  { label: 'Wellness', value: 'wellness' },
  { label: 'Personal Care', value: 'personal care' },
  { label: 'Telecom', value: 'telecom' },
  { label: 'Automobile', value: 'automobile' },
  { label: 'Mobile', value: 'mobile' },
  { label: 'Jewelry', value: 'jewelry' },
  { label: 'Education', value: 'education' },
  { label: 'Home Furnishing', value: 'home furnishing' },
  { label: 'Lifestyle', value: 'lifestyle' },
  { label: 'Spiritual', value: 'spiritual' },
  { label: 'Motivational', value: 'motivational' },
  { label: 'Mythology', value: 'mythology' },
  { label: 'Social Work', value: 'social Work' },
  { label: 'Humour', value: 'humour' },
  { label: 'Life Coach', value: 'life coach' },
  { label: 'Dance', value: 'dance' },
  { label: 'Song', value: 'song' },
  { label: 'Horror', value: 'horror' },
];
Object.freeze(CATEGORIES);

const OTP_COUNTDOWN_SECONDS = 60;
export { OTP_COUNTDOWN_SECONDS, ADDRESS_TYPES, LANGUAGES, CATEGORIES };
