import React from 'react';
import { Stack } from '@mui/material';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';

// Styled LinearProgress with border
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  '& .MuiLinearProgress-bar': {
    backgroundColor: '#8f64ce',
  },
  border: '#e9ecef',
  backgroundColor: '#e9ecef',
}));

const BrandAffinitySection = ({ brandAffinity, audienceinterests }) => {
  //   console.log('I am here to check', brandAffinity);

  return (
    <Grid container spacing={2}>
      {/* Left Grid Item for Brand Affinity */}
      <Grid item xs={6} sx={{ padding: '10px' }}>
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{ paddingTop: '50px', paddingRight: '10px', fontWeight: 'bold' }}
        >
          Audience Brand Affinity
        </Typography>

        <Stack
          direction="column"
          spacing={2}
          sx={{ padding: '5px', height: '350px', overflowY: 'scroll' }}
        >
          {brandAffinity?.map(({ name, postCount }) => {
            const progress = (postCount * 100).toFixed(0);
            return (
              <Stack direction="column" spacing={2} key={name}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="subtitle1">{name}</Typography>
                  <Typography variant="body2" sx={{ paddingRight: '10px' }}>
                    {progress}%
                  </Typography>
                </Stack>
                <BorderLinearProgress variant="determinate" value={progress} />
              </Stack>
            );
          })}
        </Stack>
      </Grid>

      <Grid item xs={6} sx={{ padding: '10px' }}>
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{ paddingTop: '50px', paddingRight: '10px', fontWeight: 'bold' }}
        >
          Audience Interests
        </Typography>

        <Stack
          direction="column"
          spacing={2}
          sx={{ padding: '5px', height: '350px', overflowY: 'scroll' }}
        >
          {audienceinterests?.map(({ name, count }) => {
            const maxCount = audienceinterests.reduce(
              (max, item) => Math.max(max, item.count),
              0
            );

            const progress = ((count / maxCount) * 100).toFixed(2);
            return (
              <Stack direction="column" spacing={2} key={name}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="subtitle1">{name}</Typography>
                  <Typography variant="body2" sx={{ paddingRight: '10px' }}>
                    {progress}%
                  </Typography>
                </Stack>
                <BorderLinearProgress variant="determinate" value={progress} />
              </Stack>
            );
          })}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default BrandAffinitySection;
