import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

const FollowersTypeBrand = ({ audienceTypeRaw }) => {
  const [progressData, setProgressData] = useState([]);

  useEffect(() => {
    const calculateData = () => {
      if (!audienceTypeRaw) return;

      const totalFollowers =
        audienceTypeRaw.real +
        audienceTypeRaw.suspicious +
        audienceTypeRaw.influencers +
        audienceTypeRaw.mass_followers;

      const newData = [
        {
          label: 'Real',
          value: getPercentage(audienceTypeRaw.real, totalFollowers),
          color: '#04DB8E',
        },
        {
          label: 'Suspicious',
          value: getPercentage(audienceTypeRaw.suspicious, totalFollowers),
          color: '#D91D4A',
        },
        {
          label: 'Influencers',
          value: getPercentage(audienceTypeRaw.influencers, totalFollowers),
          color: '#680DE4',
        },
        {
          label: 'Mass Followers',
          value: getPercentage(audienceTypeRaw.mass_followers, totalFollowers),
          color: '#B3F001',
        },
      ];

      setProgressData(newData);
    };

    calculateData();
  }, [audienceTypeRaw]);

  const getPercentage = (count, total) => {
    return total > 0 ? (count / total) * 100 : 0;
  };

  const radius = 90; // radius of the SVG circle
  const circumference = 2 * Math.PI * radius;

  if (!audienceTypeRaw) {
    return null;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{ fontWeight: 'bold' }}
          >
            Follower Types
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={3}>
          <Box
            sx={{
              position: 'relative',
              width: 200,
              height: 200,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <svg width="200" height="200" viewBox="0 0 200 200">
              <circle
                cx="100"
                cy="100"
                r={radius}
                fill="transparent"
                stroke="#e0e0e0"
                strokeWidth="10"
              />
              {
                progressData.reduce(
                  (acc, type, index) => {
                    const dasharray = (type.value / 100) * circumference;
                    const offset = acc.offset;
                    acc.elements.push(
                      <circle
                        key={type.label}
                        cx="100"
                        cy="100"
                        r={radius}
                        fill="transparent"
                        stroke={type.color}
                        strokeWidth="10"
                        strokeDasharray={`${dasharray} ${
                          circumference - dasharray
                        }`}
                        strokeDashoffset={-offset}
                        transform="rotate(-90 100 100)"
                      />
                    );
                    acc.offset += dasharray;
                    return acc;
                  },
                  { offset: 0, elements: [] }
                ).elements
              }
            </svg>
          </Box>
        </Grid>
        <Grid item xs={9} sx={{ display: 'flex', flexDirection: 'row' }}>
          {progressData.map((type, index) => (
            <Box
              key={index}
              sx={{ display: 'flex', alignItems: 'center', mb: 2 }}
            >
              <Box
                sx={{
                  width: 16,
                  height: 16,
                  bgcolor: type.color,
                  marginRight: 2,
                  marginLeft: 2,
                }}
              />
              <Typography
                sx={{ fontWeight: 'bold', fontSize: '13px' }}
                variant="body2"
              >
                {type.label}: {Math.round(type.value)}%
              </Typography>
            </Box>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default FollowersTypeBrand;
